table {
  margin-top: 2em;
  margin-bottom: 2em;

  td,th {
    border-radius: 0 !important;
  }
  tr {
    &.stripped {
      background-color: rgba(0, 128, 0, .25) !important;
    }

    td {
      label {
        span {
          top: 5px;
        }
      }
    }
  }
}