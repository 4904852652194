@import "~materialize-css/dist/css/materialize.min.css";
@import 'styles/auth.scss';
@import 'styles/table.scss';
@import 'styles/customTabs.scss';

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
}

input[type='checkbox'] + span {
  line-height: inherit;
}
