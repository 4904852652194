.auth-page {
  width: 100%;
  height:100%;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url('/images/img/mendeleev.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }

  .container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__form{
    padding: 2em;
    border-radius: .5em;
    margin: auto;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, .5);
    max-width: 30em;
    display: flex;
    flex-direction: column;

    .input-field {
      margin: .25em !important;
    }

    button {
      margin-top: 1em;
    }

    h3 {
      margin-top: 0;
    }
  }
}
